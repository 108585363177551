.adaptive-flex-direction {
  display: flex;
  flex-direction: column;
}

.footer-margin-auto-o {
  width: 100%;
  margin: 0 auto;
}

.adaptive-padding-p1 {
  padding-bottom: 2px;
}

.footer-fixed-bottom {
  position: fixed;
  bottom: 0;
}

.adaptive-padding-footer {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media screen and (min-width: 1310px) {

  .adaptive-flex-direction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .adaptive-padding-p1 {
    padding: 0;
  }

  .adaptive-padding-bottom-p2 {
    padding-bottom: 16px;
  }
}
