.height-64px {
  height: 82px;
}

.background-white {
  background: white;
}

.header-position-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.height-width-14px {
  height: 16px;
  width: 17px;
}

.width-76px-height-40px {
  width: 76px;
  height: 40px;
}

@media screen and (max-width: 900px) {
  .mob-display-none {
    display: none;
  }
  .web-margin-left-large {
    margin-left: 16px;
  }
  .web-margin-right-large {
    margin-right: 16px;
  }
}
