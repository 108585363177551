.margin-top-64px {
  margin-top: 64px;
}

.height-2px {
  height: 2px;
}

.width-18px {
  width: 18px;
}

.width-60px {
  width: 60px;
}

.margin-top-14px {
  margin-top: 14px;
}

.margin-top-10px {
  margin-top: 9px;
}
.adaptive-padding-bottom {
  padding-bottom: 100px;
}

@media screen and (min-width: 1310px) {
  .adaptive-padding-bottom {
    padding-bottom: 24px;
  }
}
