.filled-button-background {
  background: #007c9e;
}

.bordered-button-background {
  border: 1px solid #007c9e;
}

.bordered-button-black-background {
  border: 1px solid #e5e5e5;
}

.disabled-button-background {
  background: #007c9e;
  opacity: 40%;
  border: 1px solid #007c9e;
}

.filled-red-button-background {
  background: #e64539;
}

.bordered-button-red-background {
  border: 1px solid #e64539;
}

.top-right {
  top: 80px;
  right: 16px;
}

.bottom-right {
  right: 16px;
  bottom: 16px;
}

.border-radius-16px {
  border-radius: 16px;
}

.button-size {
  height: 48px;
  width: 48px;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff;
  color: #ffff;
  box-shadow: 9999px 0 0 -5px #ffff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff;
  color: #ffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #ffff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #ffff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ffff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #ffff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ffff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ffff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #ffff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ffff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ffff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #ffff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ffff;
  }
}

.dot-pulse-dark {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
  color: #cfcfcf;
  box-shadow: 9999px 0 0 -5px #cfcfcf;
  animation: dotPulseDark 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse-dark::before,
.dot-pulse-dark::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
  color: #cfcfcf;
}

.dot-pulse-dark::before {
  box-shadow: 9984px 0 0 -5px #cfcfcf;
  animation: dotPulseDarkBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-dark::after {
  box-shadow: 10014px 0 0 -5px #cfcfcf;
  animation: dotPulseDarkAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseDarkBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #cfcfcf;
  }
  30% {
    box-shadow: 9984px 0 0 2px #cfcfcf;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseDark {
  0% {
    box-shadow: 9999px 0 0 -5px #cfcfcf;
  }
  30% {
    box-shadow: 9999px 0 0 2px #cfcfcf;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseDarkAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #cfcfcf;
  }
  30% {
    box-shadow: 10014px 0 0 2px #cfcfcf;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #cfcfcf;
  }
}

@media screen and (min-width: 576px) {
  .top-right {
    top: 116px;
  }
}
