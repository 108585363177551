.width-114px-height-80px {
  width: 114px;
  height: 80px;
}
.height-60px {
  height: 60px;
}
.sample {
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.sample-true {
  white-space: normal;
  height: fit-content;
}

.rotate-image-180 {
  transform: rotate(180deg);
}

.max-width-150px {
  max-width: 150px;
}

.opacity-0 {
  opacity: 0;
}

.flex-direction-column-mobile {
  flex-direction: row;
}

.adaptive-display-gird {
  display: grid;
  grid-template-columns: auto auto;
}

.adaptive-max-width-height {
  max-width: 200px;
  max-height: 85px;
}

.adaptive-flex-direction {
  display: flex;
  flex-direction: column;
}

.adaptive-border-registrationForm {
  border-top: #e5e5e5 1px solid;
}

.adaptive-padding-registrationForm {
  padding: 16px;
}

.adaptive-text-align {
  text-align: center;
}

.adaptive-padding-registrationForm {
  padding: 24px 0 0 0;
}

.max-width-300px {
  max-width: 300px;
}

.flex-center-vertically-direction-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.adaptive-flex-direction-column-row-web {
  display: flex;
  flex-direction: column;
}

.height-fit-content {
  height: fit-content;
}

.adaptive-border-radius {
  border-radius: 0;
}

/* .line-height-10px {
  line-height: 10px;
} */

/* .selected-box-shadow {
  -webkit-box-shadow: 0px 4px 0px 0px #007c9e;
  box-shadow: 0px 4px 0px 0px #007c9e;
  border-radius: 16px;
} */

.selected-border {
  border-bottom: 4px solid #1c6ea4;
  border-radius: 4px;
}

.white-border {
  border-bottom: 4px solid white;
  border-radius: 4px;
}

.font-weight {
  font-weight: 600;
}

.line-height-20px {
  line-height: 20px;
}

.work-spacing-1px {
  word-spacing: 1px;
}

.border-left-web {
  border-left: 1px solid #e5e5e5;
}

/* for 720 and below */

@media screen and (min-width: 1310px) {
  .adaptive-display-gird {
    display: grid;
    grid-template-columns: auto auto;
  }

  .adaptive-flex-direction {
    display: flex;
    flex-direction: row;
  }

  .adaptive-border-registrationForm {
    border-left: #e5e5e5 1px solid;
    border-top: 0;
  }

  .adaptive-padding-registrationForm {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .adaptive-text-align {
    text-align: left;
  }

  .adaptive-padding-registrationForm {
    padding: 4px 0 0 0;
  }

  .web-width-60-percent {
    width: 65%;
  }

  .web-width-40-percent {
    width: 35%;
  }

  .web-padding-right-larger {
    padding-right: 24px;
  }

  .web-padding-left-larger {
    padding-left: 24px;
  }

  .flex-center-vertically-direction-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .adaptive-flex-direction-column-row-web {
    display: flex;
    flex-direction: row;
  }

  .adaptive-border-radius {
    border-radius: 8px;
  }
}

@media screen and (min-width: 1540px) {
  .adaptive-max-width-height {
    max-width: 200px;
    max-height: 85px;
  }
  .adaptive-display-gird {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

/* for mobile */
@media screen and (max-width: 900px) {
  .mob-display-none {
    display: none;
  }
}

@media screen and (max-width: 460px) {

  .flex-direction-column-mobile {
    flex-direction: column;
  }

  .border-left-web {
    border-top: 1px solid #e5e5e5;
    border-left: none;
  }

  .adaptive-display-gird {
    display: grid;
    grid-template-columns: auto;
  }
  .remove-padding-for-mobile {
    padding: 0px;
  }
}
