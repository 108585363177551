@media screen and (min-width: 900px) {
  .padding-left-240px {
    padding-left: 240px;
  }
.border-radius-default-web{
  border-radius: 8px;
}
  .padding-right-240px {
    padding-right: 240px;
  }
}
